/* ***********Content styles**************** */

* {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.container {
    width: 100%;
    margin: auto;
}

h3 {
    font-size: 18px;
    color: lightgrey;
}

h5 {
    font-size: 12px;
    color: black;
}

p {
    font-size: 14px;
    color: #262626;
}

.newpage {
    padding-top: 80px;
}

.section {
    padding-top: 40px;
    padding-bottom: 40px;
    /*display: flex;*/
    /*flex-direction: column;*/
    align-items: center;
    width: 100%;
}

.main-heading {
    color: rgb(18, 105, 204);
    font-size: 26px;
}

.underline {
    height: 04px;
    width: 4rem;
    background-color: #681ae6;
    margin-top: 10px;
    margin-bottom: 10px;
}


/*.bg-c-light {
    background-color: #f2f2f2;
}*/

#imagencarda {
    height: 100;
    width: 100;
}


/* ***********Navbar styles**************** */


/* Modify the background color */

.navbar-custom {
    background-color: rgb(21, 11, 64);
    color: white;
    align-items: center;
    height: 60px;
    width: 100%;
    display: flex;
    padding: 0.5rem 0rem;
}


/* Modify brand and text color */

.navbar-custom .navbar-brand {
    color: rgb(174, 214, 241);
}

.navbar-custom .navbar-text {
    color: white;
}

.navbar-custom:hover {
    color: rgb(174, 214, 241);
    /*will change color of text within the element on hover */
}

.nav-link:hover {
    color: rgb(174, 214, 241);
    /*will change color of text within the element on hover */
}

.navbar-toggle {
    /* (80px - button height 34px) / 2 = 23px */
    margin-top: 23px;
    padding: 9px 10px !important;
}

.btn-button1 {
    color: #4fbfa8;
    background-color: #ffffff;
    border-color: #4fbfa8;
    font-weight: bold;
    letter-spacing: 0.05em;
    border-radius: 0;
}

.btn-button1:hover,
.btn-button1:active,
.btn-button1:focus,
.btn-button1.active {
    background: #4fbfa8;
    color: #ffffff;
    border-color: #4fbfa8;
}


/* Default height for small devices */

#intro-example {
    height: 400px;
}


/* Height for devices larger than 992px */

@media (min-width: 992px) {
    #intro-example {
        height: 600px;
    }
}


/* ***********Video styles**************** */

.intro {
    width: 100%;
    height: 100vh;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}


/* darkness the video*/

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

/* ***********Images styles**************** */
.Bkground {
    /*100 del alto del dispositivo */
    height: 180vh;
    width: 100%;
    background: linear-gradient(
        135deg, 
        rgba(2,0,36,1) 0%, 
        rgba(11,12,93,1) 37%, 
        rgba(21,96,111,1) 59%, 
        rgba(61,181,205,1) 73%, 
        rgba(200,222,227,1) 95%
    );
}

.Bkground2 {
    /*100 del alto del dispositivo */
    height: 100vh;
    width: 100%;
    background: linear-gradient(
        45deg, 
        rgba(2,0,36,1) 0%, 
        rgba(11,12,93,1) 37%, 
        rgba(21,96,111,1) 59%, 
        rgba(61,181,205,1) 73%, 
        rgba(200,222,227,1) 95%
    );
}

.Bkground3 {
    /*100 del alto del dispositivo */
    height: 350vh;
    width: 100%;
    background: linear-gradient(
        90deg, 
        rgba(2,0,36,1) 0%, 
        rgba(11,12,93,1) 15%, 
        rgba(21,96,111,1) 40%, 
        rgba(61,181,205,1) 73%, 
        rgba(200,222,227,1) 95%
    );
}

/* ***********Cards styles**************** */
.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    border: 2px solid transparent;
}

.card:hover {
    /*border: 1px solid blue !important;*/
    box-shadow: 5px 10px 20px 1px rgba(255, 255, 255, 0.273) !important;
    transition: all 0.7s linear;
}

.card-body {
    padding: 0rem 0 !important; 
}

.card-text {
    padding: 0.3rem 1rem;
    font-size:  1rem; 
}

.overflow {
    overflow: hidden;
}

.card-img-top {
    transition: all 0.2s ease-in-out;
}

.card-img-top:hover {
    transform: scale(1.8);
}


/* ***********Accordion**************** */
.accordion{
    width: 100%;
    max-width: none;
    margin: 18px auto;
    color: #fff
}

.accordionHeading {
    background: #3f475d;
    padding: 14px 0;

}

.accordionHeading .container {
    /*display: flex; */
    align-items: start;
    justify-content: space-between
}

.accordionHeading p {
    letter-spacing: 1.2px;
    font-weight: 600;
}

.accordionHeading span {
    cursor: pointer;
}

.accordionContent {
    background: #38a7f3;
    /* height: 0;
    opacity: 0; */
    display: flex;
    overflow: hidden;
    transition: all 600ms ease-in-out;
    /*align-items: self-start;*/
    text-align: left;
}

/* ***********Footer styles**************** */

.footer a {
    color: #fff;
    text-decoration: none !Important;
}

.footer a:hover {
    color: grey;
}


/* ***********SocialFollow styles**************** */

.social-container {
    text-align: center;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    text-decoration: none !Important;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: line;
    position: sticky;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1px;
    padding: 0px 100px
}

a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

a.social:hover {
    transform: translateY(-2px);
}

a.youtube {
    color: #eb3223;
}

a.facebook {
    color: #4968ad;
}

a.twitter {
    color: #49a1eb;
}

a.instagram {
    color: white;
}

a.linkedin {
    color: #4968ad;
}

a.whatsapp {
    color: green;
}

a.skype {
    color: #4968ad;
}

a.web {
    color: #4968ad;
}


/* ***********Slider styles**************** */

#carouselExampleIndicators {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 1000px;
}

img {
    border-radius: 8px;
    padding: 5px;
}